.video-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .video-section h2 {
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .video-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .video-item {
    flex: 1 1 calc(33.333% - 40px);
    max-width: calc(33.333% - 40px);
    min-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .video-item iframe {
    width: 100%;
    height: 200px;
    border: none;
  }
  
  @media (max-width: 768px) {
    .video-item {
      flex: 1 1 calc(50% - 40px);
      max-width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .video-item {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  