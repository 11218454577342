.eventoTitle {
    background-color: blue; /* Cor de fundo azul */
    color: white; /* Texto branco para contrastar */
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 10px; /* Espaçamento interno para melhorar a aparência */
    display: inline-block; /* Para que o fundo azul envolva o texto */
}

.timeline {
    margin-top: 20px;
}

.event {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.time {
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
}

.title {
    font-size: 18px;
}

.programacao-detalhada {
    font-family: 'Lato', sans-serif;
  
    padding: 10px;
    
}
.programacao-evento h3 {
    font-family: 'Lato', sans-serif;
    
    background-color: #b1b0ad !important;
    padding: 10px;
    color: white;
}
.programacao-detalhada h3 {
    font-family: 'Lato', sans-serif;
    
    background-color: #b1b0ad !important;
    padding: 10px;
    color: white;
}

 

.liHorarios {
     
    background-color: rgba(189, 192, 196, 0.14) !important;
    padding: 10px;
    font-weight: 500;
    color: blue;
}

.espacamento-li {
    margin-bottom: 10px; 
    margin-left: 20px;
}
 
.nav-tabs .nav-item .nav-link {
    background-color: transparent;
    color: black;
    border: none;
}

.nav-tabs .nav-item .nav-link.active {
    background-color: #fed136 ;
    color: white;
}

.programacao-detalhada {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}

li {
     
    margin-bottom: 10px;
}

 
 
.programacao-evento {
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.programacao-evento ul {
    list-style-type: none;
    padding-left: 20px;
}

.programacao-evento ul ul {
    padding-left: 20px;
}

.programacao-evento ul ul ul {
    padding-left: 40px;
}

.programacao-evento li {
    margin-bottom: 10px;
}

.programacao-evento .row {
    margin-top: 20px;
}

.programacao-evento img {
    max-width: 100%;
}
