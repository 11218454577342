
.TituloBancodeDados{
     background: rgba(0, 0, 0, 0.3) url('../../public/images/Convenio-Img-header.jpeg');   
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  display: flex;
  /* height: 37vh; */
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  position: relative;
     
  top: -74px;
 }
 .TituloBancodeDados h1 {
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;

 }
 
 .bancodeDados > h1 {
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  font-family: "Catamaran", sans-serif;
  color: white;

}
 .bancodeDados > p {
   margin-top: 8px;
   color: rgb(4, 4, 4);
   font-size: 22px;
   font-family: 'Source Sans Pro", sans-serif';
 }
 
 .fa-play-circle {
   margin-left: 4px;
 }
 
 @media screen and (max-width: 960px) {
   .bancodeDados-container > h1 {
     font-size: 70px;
     margin-top: -150px;
   }
 }
 
 @media screen and (max-width: 768px) {
   .bancodeDados-container > h1 {
     font-size: 50px;
     margin-top: -100px;
   }
 
   .bancodeDados-container > p {
     font-size: 30px;
   }
 
 }
 
 .h2class {
  font-size: 24px;
    margin-bottom: 20px;
 }

 .widget-categories {
  display: block;
  padding: 15px 0;
  color: #000;
  font-size: 15px;
  transition: 0.3s ease;
 }

  