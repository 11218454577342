
.TituloHome {
   background: url('../../public/images/slider1.jpg') center center/cover no-repeat; 
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
 
.TituloHome > h1 {
    
  margin-top: -100px;
  font-family: "Source Sans Pro", sans-serif;

  color: #fff;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: 2px;
    font-weight: 700;
    margin-bottom: 20px;
 
}

.TituloHome > p {
  margin-top: 8px;
  color: #fff;
  font-size: 22px;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;

}

.home-btns {
  margin-top: 32px;
}

.home-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .home-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .home-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .home-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

