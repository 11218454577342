
.VrbetesIndex {
    background: rgba(0, 0, 0, 0.3) url('../../../public/images/Convenio-Img-header.jpeg'); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  display: flex;
  /* height: 37vh; */
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  position: relative;
     
  top: -74px;
 }
 
 .verbetepdfdiv {
top: 10px
 }

 .TituloBancodeDados h1 {
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
 
 }

 .container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
  

.description {
  font-family: "Catamaran", sans-serif;
    color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.8;
    text-indent: 42.55pt;
    text-align: justify;
    margin-top: 2pt;
    margin-bottom: 2pt;
 }
  
.titulo {
  font-family: "Catamaran", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;

}

 .autor {
  font-family: "Catamaran", sans-serif;
    color: #aaa;
    margin-bottom: 20px;
 
 }

 .tituloVerbete {
     display: flex;
    
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
 }

  

 .p {
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(0, 0, 0);
  font-size: 16px;
 }
   
  
 .edicoesModernas  {
  width: 100%;
  padding: 86px 0;

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.trechoTraduzido {
  width: 100%;
  padding: 20px 0;

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.ptrecho {
   padding: 2px 0;
  font-family: "Catamaran", sans-serif;
  color: black;
font-size: 16px;
line-height: 1.8;
  text-indent: 42.55pt;
  text-align: justify;
  margin-top: 2pt;
  margin-bottom: 2pt;
 }

 .paragraph {
  color: black;
 }


 .ptrechoB{
  
    padding: 0px 7px;
    font-family: "Catamaran", sans-serif;
    color: black;
    font-size: 16px;
    line-height: 1;
    text-indent: 42.55pt;
    text-align: justify;
    margin-top: 2pt;
    margin-bottom: 2pt;
}
 
 
 #icon{
  width: 25px;
    height: 25px;
    display: inline-flex;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
 }

 
  .descricaoVerbete {
    background: rgb(0 0 0 / 13%);
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: 0px;
  }
 

  .descricaoVerbeteP {
    font-family: "Source Sans Pro", sans-serif;
  color: black;
  font-size: 16px;
  padding: 0px 0;
  display: table;

  }

  .imgverbete {
    width: 98%;
    height: 100%;
    display: inline-flex;
     align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 2px;
    margin: 12px;
  }

  .text-right {
    text-align: right!important;
    padding: 10px 0px;

}

.pedicoes {
  font-family: "Source Sans Pro", sans-serif;
  color: black;
  font-size: 16px;
  padding: 2px 5px;
  text-align: justify;

}

.baixarLink {
   
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 
}

/* 
.divPdf {
  display: flex;
  width: 99%;
  padding-right: 15px;
  padding-left: 6px;
  margin-right: auto;
  margin-left: auto;
  text-align: center!important;
  flex-wrap: wrap;
  justify-content: space-evenly;
} */




.btn-main-pdf {
  background: #fed136;
  color: rgb(244, 240, 240);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  border-radius: 40px;
  position: relative;
  
  top: -74px;
}

.btn-visualizar-pdf  {
  display: flex;
  height: 14vh;
  align-items: center;
  justify-content: center;
  padding: 105px 0;
  

}

.btn-visualizar-pdf-main {
  background: #fed136;
  color: rgb(242, 237, 237);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 15px;
  text-transform: uppercase;
  border-radius: 40px;
  margin-top: 1rem;
  margin-right: 6px;
  float: right;

}

.btn-visualizar-pdf-main:hover,
.btn-visualizar-pdf-main.active  {
background :#292828;
color: #f8f7f4;

}




.btn-visualizar-verete-main {
  background: #fed136;
  color: rgb(242, 237, 237);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 15px;
  text-transform: uppercase;
  border-radius: 40px;
  margin-top: 0rem;
  margin-right: 6px;
   

}

.btn-visualizar-verete-main:hover,
.btn-visualizar-verete-main.active  {
background :#292828;
color: #f8f7f4;

}

.post-content blockquote {
  border-left: 2px solid #fed136;
  padding: 20px;
  margin-bottom: 30px;
  background: #eeeeee;
}


#SectionIndexPdf{
  background-image: url('../../../public//images/fundo-SobreBanco.jpg');
  background-color: #FFF;
   
  background-blend-mode: darken;
   color: white;
   padding: 100px 0;
   background-size: cover;

}