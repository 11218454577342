.cards_evento {
    padding: 1rem;
    background: #fff;

}

h1 {
    font-size: 2.5rem;
    text-align: center;
}

h2 {
    font-size: 1rem;
    text-align: center;


}


.cards__container_evento {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper_evento {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items_evento {
    margin-bottom: 20px;
}

.cards__item_evento {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
}

.cards__item__link_evento {
    display: flex;
    flex-flow: wrap;

    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    flex-direction: row;

}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 3s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.cards__item__img:hover {
    transform: scale(1.1);
}

.cards__item__info {


    padding: 20px 30px 30px;
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-around;
    align-items: center;
}

.cards__item__name {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
}

.cards__item__subtitle {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    text-align: center;

}

.cards__item__text {
    color: #777;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    font-family: "Catamaran", sans-serif;

}



.card-button {

    width: 56px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);

    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 200;
    letter-spacing: 0.2rem;
    padding: 0.6rem 0.9rem;
    color: white;
    border-radius: 14rem;
    display: inline-block;
    margin-top: 1rem;
    background: linear-gradient(to right,
            rgb(255, 235, 10) 0%,
            #fed136 100%);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fed136', endColorstr='#fed136', GradientType=1);
    box-shadow: #fed136 0px 0px 32px -7px;
}


@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }
}