 

.dados-title {
    font-size: 2.5rem;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-weight: 400;

  }
  
.dados-subtitle {
    font-family: "Source Sans Pro", sans-serif;
    color: #848484;
    font-size: 16px;
  
  }

  .dados-h3 {
    font-size: 18px;
    
    color: #666;
  }

  .counter {
    font-size: 2.5rem;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-weight: 400;
  }




  