
.cards_results__container_BD {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 99%;
    
  }
  
  .cards_results__wrapper_BD {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards_results__items_BD {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  /* .cards_results__item_BD {
    display: flex;
    margin: 13px 4rem;
    border-radius: 10px;  
    flex-flow: wrap;
    width: 100%;

  } */

  .cards_results__item_BD {
    display: flex;
    margin: 5px 1rem;
    border-radius: 10px;
    flex-flow: wrap;
    width: 100%;
  }
  
  .cards_results__item__link_BD {
    display: flex;
    flex-flow: wrap;
  
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    flex-direction: column;
  
  }
  
  .cards_results__item__pic-wrap-BD {
    position: relative;
    width: 100%;
    padding-top: 60%;
    overflow: hidden;
  }
  
  .cards_results__item__pic-wrap_BD {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
  }
  
  .fade-img_BD {
    animation-name: fade-img;
    animation-duration: 3s;
  }
  
  .cards_results__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards_results__item__img_BD {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards_results__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards_results__item__info_BD {
   
  
    padding: 22px 10px 12px;

      display: flex;
      flex-flow: column;
      border-radius: 10px;
      overflow: hidden;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: stretch;
      justify-content: space-around;
      align-items: center;
  }
  
  .cards_results__item__name_BD {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
      font-weight: 700;
  }
  .cards_results__item__subtitle_BD {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  
  } 

.cards_results__item__text_BD {
    color: #777;
    font-size: 15px;
    line-height: 17px;
    text-align: justify;
    font-family: "Catamaran", sans-serif;
  }
   