
.VerbetesPdfIndex {
    background: rgba(0, 0, 0, 0.3) url('../../../public/images/Convenio-Img-header.jpeg');   

     background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  display: flex;
  /* height: 37vh; */
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  position: relative;
     
  top: -174px;
 }


 .TituloBancodeDados h1 {
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
   
   }
  
   .container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
    
  
  .description {
    font-family: "Catamaran", sans-serif;
      color: black;
    font-size: 16px;
    line-height: 1.8;
      text-indent: 42.55pt;
      text-align: justify;
      margin-top: 2pt;
      margin-bottom: 2pt;
   }
     
   .p {
    font-family: "Source Sans Pro", sans-serif;
    color: black;
    font-size: 16px;
   }
     
   

   
  /* Estilo para o spinner de carregamento */
  .containerDocumentoPdf {
    text-align: center;
  }
  
  .section-subheadingDocumentoPdf {
    font-size: 24px;
  }
  
  .section-headingDocumentoPdf {
    font-size: 18px;
  }
  
  
  
  .divspinnerDocumentoPdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Estilos para o contêiner do PDF incorporado */
  .pdf-wrapperDocumentoPdf {
    text-align: center;
  }
  
  .pagePdfdocDocumentoPdf {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5;
  }
  
  .page-controlsDocumentoPdf {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .page-infoDocumentoPdf {
    font-size: 16px;
    color: white;
  }
  
  /* Estilos para os botões de página */
  .butonsPdfViewDocumentoPdf {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .butonsPdfViewDocumentoPdf button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #fed136;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  


/* Estilize a seção de carregamento */
.loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Defina a altura desejada */
  }
   
  
  .pdf-containerDocumentoPdf {
    display: inline-block;
      vertical-align: top;
      margin-right: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
   
  
  .pdf-containerDocumentoPdf canvas {
    max-width: 100%;  
    max-height: 100%;  
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
   
  
  .element.styleDocumentoPdf {
    position: absolute;
    min-width: min-content;
    min-height: min-content;
  }
  
  .main-containerDocumentoPdf {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  

 /* Estilos para dispositivos móveis (largura máxima de 768px) */
@media (max-width: 768px) {
    .VerbetesPdfIndex {
      padding: 70px 0; /* Reduza o preenchimento superior e inferior */
      font-size: 24px; /* Reduza o tamanho da fonte */
    }
  
    .section-subheadingDocumentoPdf {
      font-size: 18px; /* Reduza o tamanho da fonte */
    }
  
    .section-headingDocumentoPdf {
      font-size: 14px; /* Reduza o tamanho da fonte */
    }
  
    .pdf-containerDocumentoPdf {
        margin: 0;
        padding: 5px;
    }
   
    .page-controlsDocumentoPdf {
      margin-top: 5px; /* Reduza a margem superior */
    }
  
    .butonsPdfViewDocumentoPdf button {
        margin: 5px;
        padding: 4px 8px;
        font-size: 14px;
    }
  
    .pdf-containerDocumentoPdf canvas {
        width: 100%!important;
        height: auto!important;
    }

    .pagePdfdocDocumentoPdf {
        width: 100%;  
        max-width: none;  
        height: auto; 
        margin: 5px;
        padding: 4px 8px;
        
      }

      .section-subheadingDocumentoPdf, .section-headingDocumentoPdf {
        font-size: 14px; 
      }
  }
  
  
  