/* Título do Artigo */
.TituloArtigoI {
  background: rgba(0, 0, 0, 0.5) url('../../../../public/images/Sobre-Img-header.jpg');   
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 2.5rem;
  font-family: 'Times New Roman';
  display: flex;
  align-items: center;
  justify-content: center;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  position: relative;
  top: -74px;
}

/* Container Divulgacao */
.containerDivulgacao {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Times New Roman';
  font-size: 1.125rem;
  line-height: 1.6;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  text-align: justify;
}

/* Header Divulgacao */
.headerDivulgacao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.headerDivulgacao .logoDivulgacao img {
  height: 50px;
}

/* Fontes e Tamanhos */
.resenha-title {
  color: hwb(0 0% 36%);
  font-size: 1.125rem;
  text-transform: uppercase;
}

.titleDivulgacao {
  font-size: 3.4375rem;
  margin-bottom: 0px;
  line-height: 1.2;
  color: #333;
  text-align: left;
}

.subtitleDivulgacao {
  font-size: 1.625rem;
  margin-bottom: 20px;
  color: black;
}

/* Flexbox e Grid Layout */
.contentDivulgacao {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.book-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.book-image img {
  width: 150px;
  height: auto;
  border: 1px solid #ccc;
  margin-right: 20px;
}

.text-contentDivulgacao {
  flex: 1;
}

.footerDivulgacao {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  font-size: 0.75rem;
  color: gray;
  display: flex;
  justify-content: space-between;
  text-align: justify;
}
.highlight-letter{
  font-family: 'Times New Roman';
  font-size: 1.125rem;
}
.highlight-letter::first-letter {
  font-size: 3.75rem;
  color: rgb(126, 5, 5);
  font-weight: bold;
  float: left;
  line-height: 45px;
  padding-right: 8px;
  padding-top: 5px;
  font-family: 'Times New Roman';
}

/* Citações e Parágrafos */
.quote-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1.125rem;
  font-family: 'Times New Roman';
  font-style: italic;
  color: gray;
  margin-left: 40px;
  border-left: 4px solid gray;
  padding-left: 16px;
  text-align: justify;
}

.paragraph-content {
  font-size: 1.125rem;
  line-height: 1.6;
  text-align: justify;
  text-indent: 2em;
  font-family: 'Times New Roman';
}
.paragraph-normal{
  font-family: 'Times New Roman';
  font-size: 1.125rem;
  line-height: 1.6;
  text-align: justify;
  }
.pnormal {
  text-indent: 0em;
}

.section-title h2 {
  color: rgb(126, 5, 5);
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
  font-family: 'Times New Roman';
}

.column {
  flex: 1;
  min-width: 300px;
  text-align: justify;
}

.column-text {
  text-align: justify;
}

.column-quote {
  text-align: justify;
}

.quote-content blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1.5em;
  margin: 0.5em 0;
  font-size: 1.2em;
  font-style: italic;
}

.quote-content blockquote p {
  margin: 0;
  font-size: 0.8em;
}

.quote-content blockquote footer {
  font-size: 0.6em;
  margin-top: 0.5em;
}

.content-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.main-column {
  flex: 2;
}

.quote-column {
  flex: 1;
  max-width: 300px;
  background-color: #f7f7f7;
  padding: 10px;
  border-left: 4px solid #ccc;
}

.citacaoartigo {
  font-size: 1.625rem;
  text-align: left;
  font-family: 'Times New Roman';
}

.contentDivulgacao {
  display: flex;
  gap: 20px;
}

.quote-columnEsquerda {
  flex: 1;
  max-width: 40%;
}

.text-columnEsquerda {
  flex: 2;
  max-width: 60%;
}

.citacaoartigoEsquerda {
  font-style: italic;
  margin: 0;
  font-size: 1.625rem;
  text-align: left;
}

blockquote {
  border-left: 4px solid #ddd;
  padding-left: 20px;
  margin: 0;
  min-width: 150px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .TituloArtigoI {
      font-size: 2rem;
      padding: 100px 0 50px;
  }

  .titleDivulgacao {
      font-size: 2.5rem;
  }

  .subtitleDivulgacao {
      font-size: 1.25rem;
  }
}

 
/* Artigo II */
.content-columns-artigoII {
  display: flex;
  gap: 40px;
}

.column-artigoII {
  flex: 1;
  text-align: justify;
  font-family: 'Times New Roman';
}

.column-artigoII #h3-artigoII {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #B71C1C;
}

.column-artigoII #p-artigoII {
  font-size: 1.125rem;
  line-height: 1.6;
  text-indent: 2em;
}


 
/* ArtigoI.css */

/* Mobile Styles */
@media (max-width: 768px) {
  .TituloArtigoI {
    text-align: center;
    padding: 10px;
  }

  .containerDivulgacao {
    padding: 15px;
  }

  .book-image img {
    max-width: 100%; /* Imagem ocupa 100% da largura */
    height: auto; /* Mantém a proporção */
  }

  .main-contentDivulgacao {
    font-size: 16px; /* Ajusta a fonte para uma melhor legibilidade */
  }

  .highlight-letter {
    font-size: 18px; /* Aumenta o tamanho da letra destacada */
    line-height: 1.5; /* Melhora a legibilidade */
  }

  .paragraph-content, .paragraph-normal {
    margin-bottom: 15px; /* Aumenta o espaçamento entre parágrafos */
  }

  .section-title h2 {
    font-size: 20px; /* Título em tamanhos apropriados para mobile */
    margin: 20px 0; /* Espaço acima e abaixo do título */
  }

  .content-row {
    flex-direction: column; /* Muda a direção para coluna */
  }

  .main-column, .quote-column {
    width: 100%; /* As colunas ocupam 100% da largura */
    margin-bottom: 20px; /* Espaçamento entre colunas */
  }

  blockquote {
    font-size: 16px; /* Ajusta o tamanho da citação */
    padding: 10px; /* Espaçamento interno na citação */
    border-left: 4px solid #ccc; /* Adiciona uma linha de destaque à esquerda */
  }

  .citacaoartigo {
    font-style: italic; /* Estilo em itálico para a citação */
    text-align: center; /* Centraliza a citação */
  }
}
