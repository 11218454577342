.formulario {
    display: flex;
    flex-direction: column;
  }
  
  .formulario label {
    margin-bottom: 10px;
  }
  
  .formulario input,
  .formulario select {
    padding: 5px;
    margin-bottom: 20px;
  }

  .labeltxt{
   
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 19px;

  }

.formularioDiv{
    width: 97%;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 43px;

    margin-right: auto;
    margin-left: auto;
}

.inscricoes {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #004aae;
    font-size: 49px;
    line-height: 1;
}


.inscricoesBox {
    
    padding: 0px 0 0px;
}



.bn-Vevento {
  display: flex;
  height: 1vh;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  
  }



 .VEventoMov {
     
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 18px 0 70px;
  flex-wrap: wrap;
}