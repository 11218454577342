.cards {
    padding: 1rem;
    background: #fff;
     
  }
  
  h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  
  h2 {
    font-size: 1rem;
    text-align: center;
  
  
  }
   
  .title-page {
    font-size: 2.5rem;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-weight: 400;
  
  }
  
  .subtitle-page {
    font-family: "Source Sans Pro", sans-serif;
    color: #848484;
    font-size: 16px;
  }
  
  .cards__container_Docs {
    display: flex;
    align-items: center;
    max-width: 1120px;
    width: 99%;
    display: inline-block;

  }
   

  .cards__wrapper_Docs {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items_Docs {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .cards__items_Docs {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
 
  
  .cards__item_Docs {
    display: flex;
    margin: 10px;
    border-radius: 10px;
    flex-flow: wrap;
    width: 300px; /* Largura para desktop */
  }


  .cards__item__link_Docs {
    display: flex;
    flex-direction: column;
  
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    flex-direction: column;
  
  }
  
   
  
  .cards__item__pic-wrap_Docs {
    position: relative;
    width: 100%; 
    padding-top: 100%;
    overflow: hidden;
  }
  
  .fade-img_Docs {
    animation-name: fade-img;
    animation-duration: 3s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img_Docs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  .cards__item__info_Docs {
  padding: 0px 0px 7px;
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
  height: 100%; /* Preencha toda a altura do card */
  box-sizing: border-box; /* Garanta que o preenchimento não aumente a altura */
}

  .cards__item__name_Docs {

    max-width: 200px; 
    word-wrap: break-word;

    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
      font-weight: 700;
  }
  .cards__item__subtitle_Docs {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  
  } 

  .cards__item__text_Docs {
    color: #777;
    font-size: 15px;
    line-height: 17px;
    text-align: justify;
    font-family: "Catamaran", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis; /* Adicione reticências para texto que transborda */
  }

   .div-button {
    padding: 0px 0px 7px;
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-around;
    align-items: center;
   }
  .visualisar-button-verbete {
  
    background: rgba(217, 217, 217, 0.1);
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 2;
    border: 1px solid rgba(255, 255, 255, 0.5);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 200;
    padding: 0.6rem 0.9rem;
    color: white;
    border-radius: 14rem;
    display: inline-block;
    margin-top: 1rem;
    background: linear-gradient(to right, rgb(255, 235, 10) 0%, #fed136 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fed136', endColorstr='#fed136', GradientType=1);
    box-shadow: #fed136 0px 0px 32px -7px;
  }


  .card-button-verbete {
    font-family: "Catamaran", sans-serif;
    text-align: center;

    width: 117px;
    background: rgba(217, 217, 217, 0.1);
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 2;
    border: 1px solid rgba(255, 255, 255, 0.5);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 200;
    padding: 0.6rem 0.9rem;
    color: white;
    border-radius: 14rem;
    display: inline-block;
    margin-top: 1rem;
    background: linear-gradient(to right, rgb(255, 235, 10) 0%, #fed136 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fed136', endColorstr='#fed136', GradientType=1);
    box-shadow: #fed136 0px 0px 32px -7px;
 
  }
  
 

  @media screen and (max-width: 768px) {
    .cards__item_Docs {
      width: 100%; /* Altere a largura para 100% em dispositivos móveis */
    margin: 10px 0;
    }
  
    .cards__item__name_Docs {
      font-size: 16px; /* Tamanho de fonte menor para dispositivos móveis */
    }
  
    .cards__item__text_Docs {
      font-size: 14px; /* Tamanho de fonte menor para dispositivos móveis */
    }
  
    .cards__items_Docs {
      flex-wrap: wrap; /* Certifique-se de que os cartões quebrem para uma nova linha */
    }

    .cards__item__link_Docs {
      width: 88%; /* Defina a largura desejada para telas móveis */
    }
  
    .cards__item__img_Docs {
      width: 100%; /* Defina a largura como 100% */
      height: auto; /* Isso manterá a proporção original da imagem */
      max-width: 100%; /* Adicione uma largura máxima para evitar que as imagens fiquem muito largas */
    }

    .cards_results__item__img_Docs {
      max-width: 100%; /* Define a largura máxima como 100% do container pai */
      max-height: auto; /* Mantém a proporção original */
      display: block;
      width: 100%; /* Isso garantirá que a imagem preencha seu contêiner corretamente */
      height: auto;
    }
  }
  

 






  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items_Docs {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item_Docs {
      margin-bottom: 2rem;
    }
  }
  
  
  .widget-categories-list li {
    border-bottom: 1px solid #ddd;
  }
  
  .widget-categories-list li a {
    display: block;
    padding: 15px 0;
    color: #000;
    font-size: 15px;
    transition: 0.3s ease;
  }
  
  .widget-categories-list {
    padding-left: 0px;
  }
  
  .widget-categories-list a:hover,
  .widget-categories-list a.active  {
   color: rgb(20, 19, 19);
   color: #fed136;
  
  }
  
  #fontText {
    text-align: justify;
  
  }
  
   
 

 
  .labelDocumentosPdf {
      appearance: none;
      filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
      -webkit-transition: all .5s ease;
      filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
      transition: all .5s ease;
      border: none;
      background:rgb(255 217 1 / 23%);
      border-radius: 30px;
      text-transform: uppercase;
      box-sizing: border-box;
      padding: 15px 40px;
      font-weight: 400;
      font-size: 13px;
      cursor: cursor;
  
      margin-bottom: 14px;
  
      font-weight: bold;
  }
  
  .custom-input {
      display: inline-block;
      border: 2px solid #ccc;
      border-radius: 5px;
      padding: 5px;
    }
    
    .custom-input input {
      border: none;
      outline: none;
      padding: 5px;
      border-radius: 5px;
    }
     
    
  .DivTextField{
      margin-bottom: 25px;
  
      padding: 5px;
      border-radius: 5px;
  }
  
  .pdf-tbn-documentos {
    display: flex;
    height: 10vh;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    
    }
    
     
  
    