/* * {
  box-sizing: border-box;
  margin: 0;
  top: 0;
  padding: 0;
 }

.row {
   --bs-gutter-x: 0rem;  
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
   */
   
@font-face {
  font-family: 'SyneMono-Regular';
  src: local('SyneMono-Regular'), url(./assets/font/SyneMono-Regular.ttf) format('truetype');
} 
