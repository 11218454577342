 

.footer-container {
  background-color: #1b1b1b;
  padding: 2rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

 
.div-social-icon{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: nowrap;
}
.logos{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: nowrap;
}
     
.img-logo-unicamp{
  max-width: 28%;
 
}
.img-logo-fapesp {
  max-width: 30%;

}
/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: contents;
    justify-content: space-between;
    align-items: center;
    width: 36%;
    /* max-width: 1000px; */
    margin: 4px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 332px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}


.hrefFooter{
  color: #fed136;

}