 
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Indie+Flower&display=swap');
 
.nav {
 display: flex;
 justify-content: space-between;
}

.navbar > .containerCss {
 display: flex;
 flex-wrap: inherit;
 align-items: flex-start !important;
 justify-content: space-between;
} 

#containerCss{
 display: flex;
 flex-wrap: inherit;
 align-items: flex-start;
 justify-content: space-between;
}

nav.navbar {
 padding: 1px 0px;
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 9999;
 transition:  0.32 ease-in-out;
}

.navbar {
   position: relative;
   display: -ms-flexbox;
   display: flex;
   -ms-flex-wrap: wrap;
/*    flex-wrap: wrap; */
   -ms-flex-align: center;
   align-items: center;
   -ms-flex-pack: justify;
   justify-content: space-between;
   padding: 0.5rem 1rem;
}

nav.navbar.scrolled   .navbar-nav .nav-link.navbar-link {
 color: rgb(14, 14, 15);  
}

nav.navbar.scrolled {
 padding: 20px 0;
 background-color: white;
}

 
nav.navbar a.navbar-brand .nav-link{
/*    width: 0%; */
   color:  #fed136 !important;
   font-family: 'SyneMono-Regular';
   margin-top: 10px;
   display: inline-block; 
   font-size: 1.26rem;  
}

nav.navbar .navbar-nav .nav-link.navbar-link  {
 font-weight: 400;
 color: #fff;
 letter-spacing: 0.8px;
 /* padding: 8px 25px; */
 font-size: 14px;
 opacity: 0.75;
 text-align: center;
 font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
 line-height: 26px;
 padding: 20px 15px;
 text-transform: uppercase;
 transition: 0.2s ease-in-out 0s;
 
}

nav.navbar .navbar-nav .nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active  {
   color: #fff;
 color: #fed136;
}


span.navbar-text {
   display: flex;
   align-items: center;
}

.social-icon {
   display: inline-block;
   margin-left: 14px;
   padding: 10px 0px;

}

.social-icon a {
   width: 42px;
   height: 42px;
   background: rgba(217, 217, 217, 0.1);
   display: inline-flex;
   border-radius: 50%;
   margin-right: 6px;
   align-items: center;
   justify-content: center;
   line-height: 2;
   border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
   content: "";
   width: 42px;
   height: 42px;
   position: absolute;
   background-color: #ffffff;
   border-radius: 50%;
   transform: scale(0);
 transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
   transform: scale(1);
}

.social-icon a img {
 width: 40%;
 z-index: 1;
 transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
 filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
 font-weight: 700;
 color: #fff;
 border: 1px solid #fff;
 padding: 18px 11px;
 font-size: 14px;
 margin-left: 18px;
 position: relative;
 background-color: transparent;
 transition: 0.3s ease-in-out;
}

.navbar-text button span {
 z-index: 1;
}

.navbar-text button::before {
 content: "";
 width: 0%;
 height: 100%;
 position: absolute;
 background-color: #fff;
 left: 0;
 top: 0;
 z-index: -1;
 transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
 color: #121212;
}

.navbar-text button:hover::before {
 content: "";
 width: 100%;
 height: 100%;
 position: absolute;
}
 
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
 outline: none;
   box-shadow: none;
   background-color:  #fed136;
}

nav.navbar .navbar-toggler-icon {
 width: 24px;
 height: 17px;
 background-image: none;
 position: relative;
 border-bottom: 2px solid #fff;
 transition: all 300ms linear;
 top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
 border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
 width: 24px;
 position: absolute;
 height: 2px;
 background-color: rgb(21, 20, 20);
 top: 0;
 left: 0;
 content: '';
 z-index: 2;
 transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
 top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
 transform: rotate(45deg);
 background-color: rgb(255, 255, 255);
 height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
 transform: translateY(8px) rotate(-45deg);
 background-color: #fff;
 height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
 border-color: transparent;
}

@media screen and (max-width: 980px) {
 nav {
   background-color: #fff;
   transition: all 300ms linear;
 }
}


 
@media screen and (max-width: 996px) {
 nav {
   background-color: #fff;
   transition: all 300ms linear;
 }
}
 
@media screen and (max-width: 992px) {
 nav.navbar .navbar-nav .nav-link.navbar-link {
    color: rgb(14, 14, 15);
 }
}

.navbar-toggler .collapsed{
   padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
   font-size: var(--bs-navbar-toggler-font-size);
   line-height: 2;
   color: var(--bs-navbar-color);
   background-color: transparent;
   border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
   border-radius: var(--bs-navbar-toggler-border-radius);
   transition: var(--bs-navbar-toggler-transition);
}
