.TituloEventosSelecionados {
      background: rgba(0, 0, 0, 0.5) url('../../../public/images/slider1.jpg'); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  display: flex;
  height: 37vh;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  
 }
  

 .eventos__items{

  padding: 14px;
 }


 .EventosMov {
     
    display: flex;
    justify-content: space-between;
    font-size: 2.5rem;
    padding: 58px 0 70px;
    flex-wrap: wrap;
 }

 .EventosMov h2 {
  color: black;
  padding: 50px 0 15px;
 }

 .EventosMov h6 {
  font-size: 13px;
}

 .btn-main {
  background: #fed136;
  color: rgb(242, 237, 237);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  border-radius: 40px;
}

.pdf-tbn-evento {
display: flex;
height: 37vh;
align-items: center;
justify-content: center;
justify-content: space-evenly;

}


.pdf-tbn-evento:hover,
.pdf-tbn-evento.active  {
 color: white;
 color: #fed136;

}



#link-item {
  display: flex;
  flex-flow: column;
  width: 50%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}