
.noticias {
    padding: 0 0 50px 0;
    position: relative;

      
  }
  .noticias-bx {
    background: rgb(0 0 0 / 13%);
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: 0px;
  }
  .noticias h2 {
    font-size: 2.5rem;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-weight: 400;
  }
  .noticias p {
    color: #848484;     
     font-size: 18px;
      letter-spacing: 0.8px;
      line-height: 1.5em;
      margin: 14px 0 75px 0;
 
      font-family: "Source Sans Pro", sans-serif;
    
        
      
    
  
  }
  .noticias-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
  .noticias-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
  }
  .background-image-left {
    top: 50%;
    position: absolute;
    bottom: 0px;
    width: 30%;
    height: 43%;
    z-index: 0;
  }


  /* Ajustando a posição das setas no carrossel */
.custom-carousel .react-multi-carousel-arrow {
  top: 50%; /* Centraliza verticalmente */
  transform: translateY(-50%); /* Ajusta a centralização */
  z-index: 10; /* Certifique-se de que as setas fiquem acima dos cards */
}

/* Estilizando as setas (opcional) */
.custom-carousel .react-multi-carousel-arrow--left {
  left: -20px; /* Ajuste a posição horizontal da seta esquerda */
}

.custom-carousel .react-multi-carousel-arrow--right {
  right: -20px; /* Ajuste a posição horizontal da seta direita */
}
