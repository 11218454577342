.cards_podcast {
  padding: 1rem;
  background: #fff;
}

h1_podcast {
  font-size: 2.5rem;
  text-align: center;
}

h2_podcast {
  font-size: 1rem;
  text-align: center;
}

.cards__container_podcast {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.cards__wrapper_podcast {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items_podcast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  list-style: none;
  margin: 0;
}

.cards__item_podcast {
  flex: 1 1 calc(33.333% - 2rem);
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  overflow: hidden;
  min-height: 300px;
}

.cards__item__link_podcast {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap_podcast {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.cards__item__pic-wrap_podcast::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img_podcast {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img_podcast:hover {
  transform: scale(1.05); /* Aplica um leve zoom ao passar o mouse */

}

.cards__item__img_podcast {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: none;
  object-fit: inherit; /* Usa cover para preencher o container mantendo a proporção */

}

.cards__item__info_podcast {
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  flex-grow: 1;
}

.cards__item__name_podcast {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.cards__item__subtitle_podcast {
  color: #252e48;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}

.cards__item__text_podcast {
  color: #777;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  font-family: "Catamaran", sans-serif;
}

.card-button_podcast {
  width: 56px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 200;
  letter-spacing: 0.2rem;
  padding: 0.6rem 0.9rem;
  color: white;
  border-radius: 14rem;
  display: inline-block;
  margin-top: 1rem;
  background: linear-gradient(to right, rgb(255, 235, 10) 0%, #fed136 100%);
  box-shadow: #fed136 0px 0px 32px -7px;
}

@media only screen and (max-width: 768px) {
  .cards__items_podcast {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .cards__item_podcast {
    margin: 0.5rem;
  }

  .cards__item__pic-wrap_podcast {
    height: 200px;
  }

   
  .cards__container_podcast {
    padding: 0.5rem;
  }

  .cards__item__info_podcast {
    padding: 10px 15px;
  }

  .cards__item__name_podcast,
  .cards__item__subtitle_podcast,
  .cards__item__text_podcast {
    font-size: 14px;
    line-height: 20px;
  }

  h1_podcast {
    font-size: 2rem;
  }

  h2_podcast {
    font-size: 0.9rem;
  }

  .card-button_podcast {
    width: 40px;
    height: 36px;
    font-size: 0.7rem;
    padding: 0.5rem 0.8rem;
  }
}
