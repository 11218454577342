.containerDivulgacao {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Times New Roman', Times, serif ;
  line-height: 1.6;
  display: flex;
  flex-wrap: wrap;
  gap: 2em; /* Espaço entre as colunas */
  text-align: justify; /* Justifica o texto dentro do container */
}
  
  .headerDivulgacao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
  
  .headerDivulgacao .logoDivulgacao img {
    height: 50px;
  }
  
  .resenha-title {
    color: #B71C1C;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .main-contentDivulgacao {
    padding: 20px 0;
  }
  
  .titleDivulgacao {
    font-family: 'Times New Roman', Times, serif ;

    font-size: 36px; 
     
    margin-bottom: 10px;
    line-height: 1.2; 
    color: #333; 
  }
  .subtitleDivulgacao  {
    font-family: 'Times New Roman', Times, serif ;
    font-size: 26px; 
    
    margin-bottom: 20px;
  }
  
   
  
  .contentDivulgacao {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .book-details {
  display: flex;
  flex-direction: column; /* Alterar para coluna para empilhar a imagem e o texto verticalmente */
  gap: 20px; /* Espaçamento entre a imagem e o texto */
}

  .book-image img {
    width: 150px;
    height: auto;
    border: 1px solid #ccc;
    margin-right: 20px;
  }
 
  .text-contentDivulgacao {
    flex: 1;
  }
  
  .footerDivulgacao {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    font-size: 12px;
    color: gray;
    display: flex;
    justify-content: space-between;
    font-family: 'Times New Roman', Times, serif ;
    text-align: justify; /* Justifica o texto no rodapé */

  }
  

  .highlight-letter::first-letter {
    font-size: 60px; /* Aumente o tamanho da letra */
    color: red; /* Cor vermelha para a letra */
    font-weight: bold; /* Negrito para a primeira letra */
    float: left; /* Flutua à esquerda */
    line-height: 45px; /* Ajusta a altura da linha para alinhar com o texto */
    padding-right: 8px; /* Espaçamento entre a letra e o texto */
    padding-top: 5px; /* Espaçamento superior para alinhar melhor */
    font-family: 'Times New Roman', Times, serif ;

  }
  

  .resenha-content {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaçamento entre os blocos */
  }
  
  .paragraph-content {
    font-size: 18px;
    line-height: 1.6;
    
    text-align: justify; /* Justifica o texto dentro da coluna de citação */
    text-indent: 2em; /* Indenta a primeira linha do parágrafo em 2em */

  }

  .pnormal {
    text-indent: 0em; /* Indenta a primeira linha do parágrafo em 2em */

  }
  
  
  .quote-content {
    font-size: 24px;
    font-style: italic;
    color: gray;
    margin-left: 40px;
    border-left: 4px solid gray;
    padding-left: 16px;
    text-align: justify; /* Justifica o texto dentro da coluna de citação */

  }
  
  .section-title h2 {
    color: red;
    font-weight: bold;
    font-size: 24px;
  }
  

  .column {
    flex: 1;
    min-width: 300px; /* Largura mínima para garantir que o layout fique bom em telas menores */
    text-align: justify; /* Justifica o texto dentro das colunas */
  }
  
  .column-text {
    text-align: justify; /* Justifica o texto dentro da coluna de texto */
  }
  
  .column-quote {
    text-align: justify; /* Justifica o texto dentro da coluna de citação */
  }
  
   
   

  .quote-content blockquote {
    border-left: 4px solid #ccc;
    padding-left: 1.5em; /* Aumenta o preenchimento à esquerda */
    margin: 0.5em 0; /* Ajuste a margem para separar o bloco do restante do conteúdo */
    font-size: 1.2em; /* Aumenta o tamanho da fonte */
    font-style: italic; /* Mantém o estilo em itálico */
    font-family: 'Times New Roman', Times, serif ;

  }
  
  .quote-content blockquote p {
    margin: 0; /* Remove a margem do parágrafo dentro do blockquote para melhor controle */
    font-size: 0.8em;
  }
  
  .quote-content blockquote footer {
    font-size: 0.6em; /* Ajuste o tamanho da fonte do rodapé, se necessário */
    margin-top: 0.5em; /* Adiciona um espaço acima do rodapé */
  }


  .cards_artigo {
    padding: 2rem;
    background: #f4f4f4; /* Alterado para uma cor de fundo mais suave */
  }

 