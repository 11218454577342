.widget-categories-list li a {
    display: block;
    padding: 15px 0;
    color: #000;
    font-size: 15px;
    transition: 0.3s ease;
    
}

  
.h1Autor {
    font-size: 2rem;
    text-align: center;
  }
  
  .h2Autor {
    font-size: 1rem;
    text-align: center;
  
  
  }
   
  .title-page {
    font-size: 2.5rem;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-weight: 400;
  
  }
  
  .text-center-autores {
    font-family: "Catamaran", sans-serif;
    font-weight: 600;
    text-align: center!important;
    margin: 2px 1rem;
    border-radius: 10px;
    flex-flow: wrap;
    width: 100%;
    align-content: stretch;
    justify-content: space-evenly;
    margin: 0px 0px 1rem;
}

.pAutores {
  font-family: "Source Sans Pro", sans-serif;
  color: #848484;
  font-size: 16px;
}

.pAutorLink{
  font-family: "Source Sans Pro", sans-serif;
  color: #848484;
  font-weight: 600;

  text-align: center!important;
  margin: 2px 1rem;
  border-radius: 10px;
  flex-flow: wrap;
  width: 100%;
  align-content: stretch;
  justify-content: space-evenly;
}

.about-2 .checklist li {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    color: #777;
    font-family: "Source Sans Pro", sans-serif;
}

.ptextoautor{
  font-family: "Source Sans Pro", sans-serif;
  color: #848484;;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 0;
  text-align: justify;

}
.textlinkdescription{
  font-family: "Source Sans Pro", sans-serif;
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 0;
}
.div-ptextoautor{
   
  text-align: center!important;
  margin: 2px 0rem;
  border-radius: 10px;
  flex-flow: wrap;
  width: 94%;
  align-content: stretch;
  justify-content: space-evenly;
}

.autores-bd{
    
  justify-content: space-evenly;
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 1px 5px 0rem;
}


.autores-tbn-evento {
  display: flex;
  height: 37vh;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  
  }
  
  
  .autores-tbn-evento:hover,
  .autores-tbn-evento.active  {
   color: white;
   color: #fed136;
  
  }