
.VerbetesPdfIndex {
    background: rgba(0, 0, 0, 0.3) url('../../../public/images/Convenio-Img-header.jpeg');   

     background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  display: flex;
  /* height: 37vh; */
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  position: relative;
     
  top: -174px;
 }


 .TituloBancodeDados h1 {
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
   
   }
  
   .container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
    
  
  .description {
    font-family: "Catamaran", sans-serif;
      color: black;
    font-size: 16px;
    line-height: 1.8;
      text-indent: 42.55pt;
      text-align: justify;
      margin-top: 2pt;
      margin-bottom: 2pt;
   }
     
   .p {
    font-family: "Source Sans Pro", sans-serif;
    color: black;
    font-size: 16px;
   }
     
   
     
  
  #SectionIndexPdf{
    background-image: url('../../../public//images/fundo-SobreBanco.jpg');
    background-color: #FFF;
     
    background-blend-mode: darken;
     color: white;
     padding: 100px 0;
     background-size: cover;
  
  }


.divPdf {
    display: flex;
    width: 99%;
    padding-right: 4px;
    padding-left: 6px;
    margin-right: auto;
    margin-left: auto;
    text-align: center!important;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
  }

  .react-pdf__Page__textContent {
    display: none;
  }

  .custom-text-field {
    width: 300px; /* Ajuste o tamanho conforme necessário */
  }

/* Adicione essas classes de estilo no seu arquivo CSS (por exemplo, VerbetesPDF.css) */

/* Estilize a seção de pesquisa e navegação */
 
/* Estilize os campos de pesquisa e botões */
.search-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.search-input-container > * {
  margin-bottom: 10px;
}

/* Estilize os botões de página */
.page-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  
}

.page-buttons-container > button {
  margin: 0 10px;
}

/* Estilize a seção de carregamento */
.loading-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Defina a altura desejada */
}
 

.pdf-container {
  display: inline-block;
    vertical-align: top;
    margin-right: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

 

.pdf-container canvas {
  max-width: 100%;  
  max-height: 100%;  
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

 

.element.style {
  position: absolute;
  min-width: min-content;
  min-height: min-content;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


 
.pagination-container {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  margin-top: 20px;
  font-family: "Source Sans Pro", sans-serif;
}

.pagination-container button {
  margin: 0 10px; /* Adicione espaço entre os botões */
}

/* Estilize o título "Resultados encontrados" */
.search-results h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Estilize a tabela */
.search-results table {
  width: 100%;
  border-collapse: collapse;
}

/* Estilize as células da tabela */
.search-results th,
.search-results td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

/* Estilize a célula da página */
.search-results .page-number {
  font-weight: bold;
  background-color: #f0f0f0;
}

/* Estilize a célula de texto da pesquisa */
.search-results .search-text {
  font-style: italic;
  font-family: "Source Sans Pro", sans-serif;
}

.page-count {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fed136;
  text-align: center;
}

.DivTextField{
   

  margin-bottom: 25px;
    padding: 5px;
    border-radius: 5px;
     
    margin: 1.2rem;
    padding: 1px 100px;
    position: center;
     
    /* background: #fed136; */
    text-align: center;
     
}


.bottom-loading {
  text-align: center;
  margin-top: 20px; /* Espaço entre os resultados e o ícone de carregamento */
}



.search-button {
  margin-left: 20px;
  height: 2.5rem;
    margin: 1.2rem;
    padding: 1px 100px;
    position: center;
    
    background: #fed136;
    text-align: center;
   
}



 

.page-info {
  display: flex;
  justify-content: center;
  font-size: 16px; /* Tamanho da fonte aumentado para 16 pixels */
  color: white; /* Cor do texto branca */
  margin-top: 10px;
  width: 100;

}

 

.pdf-wrapper .react-pdf__Document {
  display: block;
  margin: 0 auto;
  align-items: center;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.page-pdfdoc {
  flex-grow: 1;
  align-self: center; /* Centraliza horizontalmente */
  display: flex;
 
  justify-content: center; /* Centraliza verticalmente */
  align-items: center;
}

  .butonsPdfView{
    align-self: center; /* Centraliza horizontalmente */
  display: flex;
 
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  }

.page-controls {
  align-self: center; /* Centraliza horizontalmente */
  display: flex;
 
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
}

