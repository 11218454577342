p {
    font-family: "Source Sans Pro", sans-serif;
    color:#0505058f;

    font-size: 16px;
}

.btn-button-autor {
  
    background: rgba(217, 217, 217, 0.1);
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 2;
    border: 1px solid rgba(255, 255, 255, 0.5);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 200;
    padding: 0.6rem 0.9rem;
    color: white;
    border-radius: 14rem;
    display: inline-block;
    margin-top: 1rem;
    background: linear-gradient(to right, rgb(255, 235, 10) 0%, #fed136 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fed136', endColorstr='#fed136', GradientType=1);
    box-shadow: #fed136 0px 0px 32px -7px;
  }

.btn-button-autor-main  {
    background: #fed136;
    color: rgb(244, 240, 240);
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 12px 40px;
    text-transform: uppercase;
    border-radius: 40px;
    position: relative;
       
    top: -74px;
  }
  
  .btn-main-autor  {
    display: flex;
    height: 14vh;
    align-items: center;
    justify-content: center;
    padding: 105px 0;
  }
  
  .btn-autor-main-autor {
    background: #fed136;
    color: rgb(242, 237, 237);
    display: flow-root;

    font-size: 14px;
    letter-spacing: 1px;
    padding: 12px 40px;
    text-transform: uppercase;
    border-radius: 40px;
  }
  
.btn-autor-main-autor:hover,
.btn-autor-main-autor.active  {
  background :#292828;
 color: #f8f7f4;

}
  
.divAlgunsTrabalhos {
  margin: 3px 0rem;
}

.img-fluid-autores { 
    height: auto;
    max-width: 40%;
}
 