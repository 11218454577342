 
.convenios {
  background: rgba(0, 0, 0, 0.5) url('../../public/images/Convenio-Img-header.jpeg'); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
display: flex;
/* height: 37vh; */
align-items: center;
justify-content: center;
background-blend-mode: darken;
font-size: 2.5rem;
padding: 140px 0 70px;
position: relative;
     
top: -74px;
} 
 
.convenio-section {
  padding: 105px 0;
    background: #fff
}
.convenios h1 {
   
  color: #fff;
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  font-family: "Catamaran", sans-serif;
  color: white;
}
.h2class {
  font-size: 2rem;
  font-family: "Source Sans Pro", sans-serif;

}

p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
 }
 
#fontTextConvenios {
  text-align: justify;
  font-size: 14px;

}

.btn-main {
  background: #fed136;
  color: rgb(244, 240, 240);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  border-radius: 40px;
  position: relative;
     
  top: -74px;
}

.btn-evento {
  display: flex;
  height: 14vh;
  align-items: center;
  justify-content: center;
  padding: 105px 0;
}

.btn-evento-main {
  background: #fed136;
  color: rgb(242, 237, 237);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  border-radius: 40px;
}

.btn-evento-main:hover,
.btn-evento-main.active  {
  background :#292828;
 color: #f8f7f4;

}

.convenio-section h6 {
  font-size: 13px;
}