 
 .contact {
  background: rgba(0, 0, 0, 0.3) url('../../public/images/FaleConoscoImg.jpeg'); 
  /* background: linear-gradient(90.21deg, #fbce36 -5.91%, #faad14 111.58%); */
  padding: 90px 0 200px 0;

}

.faleConoscoHeader {
  background: rgba(0, 0, 0, 0.5) url('../../public/images/Convenio-Img-header.jpeg'); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
display: flex;
/* height: 37vh; */
align-items: center;
justify-content: center;
font-size: 2.5rem;
background-blend-mode: darken;
padding: 140px 0 70px;
position: relative;
   
top: -200px;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color: #fff;
}
 
.contact p {
	font-size: 20px;
	font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

.contact h3 {
	font-size: 30px;
	font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

.contact span {
	font-size: 19px;
	font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: rgb(5, 5, 5);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #fed136;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
   

}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
 
#icons {
  width: 25px;
  height: 25px;
  display: inline-flex;
  /* margin-right: 6px; */
  align-items: center;
  justify-content: center;
  line-height: 1;
 
}

.form-contato {
padding: 3px;
margin: 14px;
    top: 1px; 
}

.contact-details  {
  padding: 3px;
margin: 14px;
    top: 1px;
}

