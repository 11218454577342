.DivulgacaoCientifica {
    background: rgba(0, 0, 0, 0.5) url('../../public/images/Sobre-Img-header.jpg');   
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  display: flex;
  /* height: 37vh; */
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-blend-mode: darken;
  padding: 140px 0 70px;
  position: relative;
     
  top: -74px;
 }
  
   
  p {
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    font-size: 16px;
  }
   
  .DivulgacaoCientifica h1 {
    font-weight: 400;
  font-size: 2rem;
  text-align: center;
  font-family: "Catamaran", sans-serif;
  color: white;

  }
  
  .DivulgacaoCientifica  h2 {
    font-size: 1rem;
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    color: #848484;

  }
  
  
  .page-section {
    padding: 6rem 0 0;
  }
  
  .page-section h2.section-heading {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  .page-section h3.section-subheading {
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 4rem;
  }
  
  .section-sm {
    padding: 70px 0;
  }
  
    
   