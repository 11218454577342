.cards_artigo {
    padding: 1rem;
    background: #fff;

}

h1 {
    font-size: 2.5rem;
    text-align: center;
}

h2 {
    font-size: 1rem;
    text-align: center;


}


.cards__container_artigo {
    display: flex;
    flex-wrap: wrap; /* Permite que os cartões quebrem para a linha seguinte se necessário */
    justify-content: center; /* Alinha os cartões horizontalmente no centro */
    max-width: 100%; /* Ajuste conforme necessário */
    width: 100%; /* Usa a largura total disponível */
    margin: 0 auto; /* Centraliza o contêiner horizontalmente */
    padding: 1rem; /* Adiciona espaçamento ao redor do contêiner */
}

.cards__wrapper_artigo {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items_artigo {
    display: flex;
    flex-direction: row; /* Organiza os itens em linha */
    justify-content: space-between; /* Distribui o espaço entre os itens */
    gap: 20px; /* Espaçamento entre os itens */
    padding: 10px;
    list-style: none; /* Remove a numeração das listas, se houver */
    margin: 0;
}

.cards__item_artigo {
    flex: 1 1 calc(33.333% - 2rem); /* Cada item ocupa 1/3 da largura do contêiner menos a margem */
    margin: 1rem; /* Espaçamento ao redor dos itens */
    border-radius: 10px; /* Bordas arredondadas */
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17); /* Sombra dos cartões */
    overflow: hidden; /* Garante que o conteúdo não saia dos limites do cartão */
    min-height: 300px; /* Altura mínima do card */
}

.cards__item__link_artigo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; /* Garante que o link ocupe a altura total do card */
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;

}

.cards__item__pic-wrap_artigo {
    position: relative;
    width: 100%;
    height: 300px; /* Aumente a altura conforme necessário */
    overflow: hidden;
}
.fade-img {
    animation-name: fade-img;
    animation-duration: 3s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.cards__item__img_artigo {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: inherit; /* Usa cover para preencher o container mantendo a proporção */
    transition: all 0.2s linear;
}

.cards__item__img_artigo:hover {
    transform: scale(1.1);
}

.cards__item__info_artigo {

    padding: 20px 30px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    flex-grow: 1;
}

.cards__item__name {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
}

.cards__item__subtitle {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    text-align: center;

}

.cards__item__text {
    color: #777;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    font-family: "Catamaran", sans-serif;

}



.card-button {

    width: 56px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);

    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 200;
    letter-spacing: 0.2rem;
    padding: 0.6rem 0.9rem;
    color: white;
    border-radius: 14rem;
    display: inline-block;
    margin-top: 1rem;
    background: linear-gradient(to right,
            rgb(255, 235, 10) 0%,
            #fed136 100%);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fed136', endColorstr='#fed136', GradientType=1);
    box-shadow: #fed136 0px 0px 32px -7px;
}


 
@media only screen and (max-width: 768px) {
    .cards__items_artigo {
        display: grid; /* Usa o grid layout */
        grid-template-columns: repeat(2, 1fr); /* Cria 2 colunas com a mesma largura */
        gap: 10px; /* Espaçamento entre as colunas */
    }

    .cards__item_artigo {
        margin: 0.5rem; /* Margem ao redor dos cartões */
    }
    .cards__item__pic-wrap_artigo {
        height: 200px; /* Ajuste a altura da imagem para telas pequenas */
    }

    .cards__item__img_artigo {
        object-fit: cover; /* Mantém a proporção da imagem e preenche o espaço disponível */
        width: 100%; /* Garante que a imagem ocupe toda a largura do container */
        height: 100%; /* Preenche a altura definida */
        transition: none; /* Remove o efeito de zoom ao passar o mouse em dispositivos móveis */
    }
}

 
@media only screen and (max-width: 768px) {
    

    .cards__container_artigo {
        padding: 0.5rem; /* Reduz o espaçamento em torno do contêiner */
    }

    

    .cards__item__info_artigo {
        padding: 10px 15px; /* Reduz o preenchimento dentro do conteúdo do cartão */
    }

    .cards__item__name,
    .cards__item__subtitle,
    .cards__item__text {
        font-size: 14px; /* Reduz o tamanho da fonte para melhor leitura em telas menores */
        line-height: 20px; /* Ajusta a altura da linha para fontes menores */
    }

    h1 {
        font-size: 2rem; /* Reduz o tamanho do título principal */
    }

    h2 {
        font-size: 0.9rem; /* Reduz o tamanho do subtítulo */
    }

    .card-button {
        width: 40px;
        height: 36px;
        font-size: 0.7rem; /* Ajusta o tamanho do botão e do texto */
        padding: 0.5rem 0.8rem;
    }
}
